import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { report } from '../redux/reportSlice';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Card from '../components/Card';

const Report = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.report.data);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(report({ token }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch, token]);

    return (
        <div className="text-gray-900">
            <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <main className={`${isSidebarOpen ? 'md:w-[calc(100%-256px)]' : 'md:w-full'} ${isSidebarOpen ? 'md:ml-64' : 'md:ml-full'} bg-gray-50 min-h-screen transition-all main`}>
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                        {Object.entries(data).map(([key, value], index) => (
                            <Card key={index} title={key} value={value} />
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Report;
