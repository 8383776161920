import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchWithdrawals = createAsyncThunk('withdrawals/fetchWithdrawals', async ({token}, thunkAPI) => {
    try {
        const res = await axios.post("https://arguscompany.io/api/withdrawals", {token});
        return res.data;
    } catch (e) {
        console.log(e.message)
        return thunkAPI.rejectWithValue(e.message)
    }
})

const initialState = {
    users: '',
    loading: false,
    error: null,
    dataAvailable: false,
};

export const withdrawalSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWithdrawals.fulfilled, (state, action) => {
                state.users = action.payload.users;
                state.dataAvailable = true;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchWithdrawals.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWithdrawals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default withdrawalSlice.reducer;