import React, {Fragment, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {store} from "./redux/store";
import {Provider} from "react-redux";
import routes from "./utils/routes.js";
import Login from "./pages/Login";
import Report from "./pages/Report";
import Users from "./pages/Users";
import Withdrawal from "./pages/Withdrawal";
import Transaction from "./pages/Transaction";


function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Fragment>
                    <Routes>
                        <Route exact path={routes.login} element={<Login/>}/>
                        <Route exact path={routes.report} element={<Report/>}/>
                        <Route exact path={routes.users} element={<Users/>}/>
                        <Route exact path={routes.withdrawal} element={<Withdrawal/>}/>
                        <Route exact path={routes.transaction} element={<Transaction/>}/>
                    </Routes>
                </Fragment>
            </BrowserRouter>
        </Provider>
    );
}

export default App;