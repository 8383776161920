import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import download from "../assets/img/download.png";
import {sidebar} from "../utils/constants";

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
    const [activeMenu, setActiveMenu] = useState(0);

    const handleMenuClick = (index) => {
        setActiveMenu(index);
    };

    useEffect(() => {
        // Toggle Tailwind CSS classes for visibility
        const sidebarElement = document.getElementById('sidebar');
        if (sidebarElement) {
            sidebarElement.classList.toggle('hidden', !isSidebarOpen);
            sidebarElement.classList.toggle('sm:block', isSidebarOpen);
        }
    }, [isSidebarOpen]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            const sidebarElement = document.getElementById('sidebar');
            const toggleButton = document.querySelector('.sidebar-toggle');

            // Check if the click occurred outside of the sidebar and the toggle button
            if (isSidebarOpen && sidebarElement && !sidebarElement.contains(e.target) && !toggleButton.contains(e.target)) {
                toggleSidebar();
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleOutsideClick);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isSidebarOpen, toggleSidebar]);




    return (
        <>
            {isSidebarOpen && (
            <div id="sidebar" className="hidden sm:block fixed left-0 top-0 w-64 h-full bg-gray-900 p-4 z-50 sidebar-menu transition-transform">
                <a href="#" className="flex items-center pb-4 border-b border-b-gray-800">
                    <img src={download} alt="" className="w-8 h-8 rounded object-cover"/>
                    <span className="text-lg font-bold text-white ml-3">Logo</span>
                </a>
                <ul className="mt-4">
                    {sidebar.map((item, index) => (
                        <li key={index} className="mb-1 group">
                            <Link to={item.link}
                                className={`flex items-center py-2 px-4 text-gray-300 hover:bg-gray-950 hover:text-gray-100 rounded-md 
                            ${activeMenu === index ? 'bg-gray-800 text-white' : ''}
                            ${activeMenu !== index ? 'text-gray-300' : ''}`}
                                onClick={() => handleMenuClick(index)}
                            >
                                <i className={`${item.icon} mr-3 text-lg`}></i>
                                <span className="text-sm">{item.title}</span>
                            </Link>
                        </li>
                    ))}
                    <li className="mb-1 group">
                        <a className={`flex items-center py-2 px-4 text-gray-300 hover:bg-gray-950 hover:text-gray-100 rounded-md `}>
                            <i className={`ri-logout-box-r-line mr-3 text-lg`}></i>
                            <span className="text-sm">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
            )}

        </>
    );
}

export default Sidebar;
