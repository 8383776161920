import React from 'react';

const Card = (props) => {
    return (
        <div className="bg-white rounded-md border border-gray-100 p-6 shadow-md shadow-black/5">
            <div className="flex justify-between mb-6">
                <div>
                    <div className="text-2xl font-semibold mb-1">{props.value}</div>
                    <div className="text-sm font-medium text-gray-400">{props.title}</div>
                </div>
            </div>
        </div>
    );
}

export default Card;