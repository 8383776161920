import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from 'axios';

export const login = createAsyncThunk('/auth/signup', async ({username, password}, thunkAPI) => {
    try {
        const res = await axios.post("https://arguscompany.io/api/auth", {username, password});
        return res.data;
    } catch (e) {
        console.log(e.message)
        return thunkAPI.rejectWithValue(e.message)
    }
})

const initialState = {
    token: '',
    isLoggedIn: false,
    loading: false,
    error: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state, action) => {
            state.token = ''
            state.isLoggedIn = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.token
                state.isLoggedIn = true
                state.loading = false
                state.error = null
            })
            .addCase(login.pending, (state, action) => {
                state.loading = true
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false
                state.isLoggedIn = false
                state.error = action.payload
            })
    }
})

export const {logout} = authSlice.actions;

export default authSlice.reducer;