import React, {useState} from 'react';
import hamburger from "../assets/img/hamburger.png";
import 'remixicon/fonts/remixicon.css'


const Navbar = ({ toggleSidebar }) => {
    return (
        <>
            <div className="py-2 px-6 bg-white flex items-center shadow-md shadow-black/5 sticky top-0 left-0 z-30">
                <button
                    type="button"
                    className="text-lg text-gray-600 sidebar-toggle"
                    onClick={toggleSidebar}
                >
                    <i className="ri-menu-line"></i>
                </button>
                <ul className="flex items-center text-sm ml-4">
                    <li className="mr-2">
                        <a href="#"
                           className="text-gray-400 hover:text-gray-600 font-medium">Dashboard</a>
                    </li>
                    <li className="text-gray-600 mr-2 font-medium">/</li>
                    <li className="text-gray-600 mr-2 font-medium">Analytics</li>
                </ul>
                <ul className="ml-auto flex items-center">
                    <li className="mr-1 dropdown">
                        <button type="button"
                                className="dropdown-toggle text-gray-400 w-8 h-8 rounded flex items-center justify-center hover:bg-gray-50 hover:text-gray-600">
                            <i className="ri-search-line"></i>
                        </button>
                        <div
                            className="dropdown-menu shadow-md shadow-black/5 z-30 hidden max-w-xs w-full bg-white rounded-md border border-gray-100">
                            <form action="" className="p-4 border-b border-b-gray-100">
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        className="py-2 pr-4 pl-10 bg-gray-50 w-full outline-none border border-gray-100 rounded-md text-sm focus:border-blue-500"
                                        placeholder="Search..."
                                    />
                                    <i className="ri-search-line absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400"/>
                                </div>
                            </form>
                            <div className="mt-3 mb-2">
                                <div
                                    className="text-[13px] font-medium text-gray-400 ml-4 mb-2">Recently
                                </div>
                                <ul className="max-h-64 overflow-y-auto">
                                    <li>
                                        <a href="#" className="py-2 px-4 flex items-center hover:bg-gray-50 group">
                                            <img
                                                src="https://placehold.co/32x32"
                                                alt=""
                                                className="w-8 h-8 rounded object-cover align-middle"
                                            />
                                            <div className="ml-2">
                                                <div
                                                    className="text-sm text-gray-600 font-medium truncate group-hover:text-blue-500">
                                                    Create landing page
                                                </div>
                                                <div className="text-xs text-gray-400">$345</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown">
                        <button type="button"
                                className="dropdown-toggle text-gray-400 w-8 h-8 rounded flex items-center justify-center hover:bg-gray-50 hover:text-gray-600">
                            <i className="ri-notification-3-line"></i>
                        </button>
                        <div
                            className="dropdown-menu shadow-md shadow-black/5 z-30 hidden max-w-xs w-full bg-white rounded-md border border-gray-100">
                            <div
                                className="flex items-center px-4 pt-4 border-b border-b-gray-100 notification-tab">
                                <button type="button" data-tab="notification"
                                        data-tab-page="notifications"
                                        className="text-gray-400 font-medium text-[13px] hover:text-gray-600 border-b-2 border-b-transparent mr-4 pb-1 active">Notifications
                                </button>
                                <button type="button" data-tab="notification"
                                        data-tab-page="messages"
                                        className="text-gray-400 font-medium text-[13px] hover:text-gray-600 border-b-2 border-b-transparent mr-4 pb-1">Messages
                                </button>
                            </div>
                            <div className="my-2">
                                <ul className="max-h-64 overflow-y-auto"
                                    data-tab-for="notification"
                                    data-page="notifications">
                                    <li>
                                        <a href="#" className="py-2 px-4 flex items-center hover:bg-gray-50 group">
                                            <img
                                                src="https://placehold.co/32x32"
                                                alt=""
                                                className="w-8 h-8 rounded block object-cover align-middle mr-2" // Added margin to separate image and text
                                            />
                                            <div>
                                                <div
                                                    className="text-sm text-gray-600 font-medium truncate group-hover:text-blue-500">
                                                    New order
                                                </div>
                                                <div className="text-xs text-gray-400">from a user</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="max-h-64 overflow-y-auto hidden"
                                    data-tab-for="notification" data-page="messages">
                                    <li>
                                        <a href="#" className="py-2 px-4 flex items-center hover:bg-gray-50 group">
                                            <img
                                                src="https://placehold.co/32x32"
                                                alt=""
                                                className="w-8 h-8 rounded block object-cover align-middle mr-2" // Added margin to separate image and text
                                            />
                                            <div>
                                                <div
                                                    className="text-sm text-gray-600 font-medium truncate group-hover:text-blue-500">
                                                    John Doe
                                                </div>
                                                <div className="text-xs text-gray-400">Hello there!</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown ml-3">
                        <button type="button" className="dropdown-toggle flex items-center">
                            <img
                                src="https://placehold.co/32x32"
                                alt=""
                                className="w-8 h-8 rounded block object-cover align-middle"
                            />
                        </button>
                        <ul className="dropdown-menu shadow-md shadow-black/5 z-30 hidden py-1.5 rounded-md bg-white border border-gray-100 w-full max-w-[140px]">
                            <li>
                                <a href="#"
                                   className="flex items-center text-[13px] py-1.5 px-4 text-gray-600 hover:text-blue-500 hover:bg-gray-50">Profile</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Navbar;