import {configureStore} from '@reduxjs/toolkit';
import authSlice from './authSlice';
import reportSlice from './reportSlice';
import userSlice from './userSlice';
import transactionSlice from './transactionSlice';
import withdrawalSlice from './withdrawalSlice';

export const store = configureStore({
    reducer:{
        auth: authSlice,
        report: reportSlice,
        user: userSlice,
        transaction: transactionSlice,
        withdrawal: withdrawalSlice
    }
});