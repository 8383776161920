import React from 'react';

const Table = (props) => {
    let tableData = [];
    let tableHeaders = [];

    if (Array.isArray(props.data) && props.data.length > 0) {
        tableData = props.data;
        tableHeaders = Object.keys(tableData[0]);
    } else if (typeof props.data === 'object' && Object.keys(props.data).length > 0) {
        tableData = [props.data];
        tableHeaders = Object.keys(props.data);
    }

    return (
        <div className="p-6">
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-12 mb-12">
                <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md">
                    <div className="flex justify-between mb-4 items-start">
                        <div className="font-medium">{props.title}</div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[540px]" data-tab-for="order" data-page="active">
                            <thead>
                            <tr>
                                {tableHeaders.map((header, index) => (
                                    <th key={index} className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((item, rowIndex) => (
                                <tr key={rowIndex}>
                                    {tableHeaders.map((header, colIndex) => (
                                        <td key={colIndex} className="py-2 px-4 border-b border-b-gray-50">
                                            {item[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
