import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const report = createAsyncThunk('report/fetchReport', async ({ token }, thunkAPI) => {
    try {
        const res = await axios.post("https://arguscompany.io/api/reports", { token });
        return res.data;
    } catch (e) {
        console.log(e.message);
        return thunkAPI.rejectWithValue(e.message);
    }
});

const initialState = {
    data: '',
    loading: false,
    error: null,
    dataAvailable: false, // Added property to track data availability
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(report.fulfilled, (state, action) => {
                state.data = action.payload;
                state.dataAvailable = true; // Set dataAvailable to true
                state.loading = false;
                state.error = null;
            })
            .addCase(report.pending, (state) => {
                state.loading = true;
            })
            .addCase(report.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default reportSlice.reducer;
