import React,{useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../redux/authSlice';


const Login = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const token = useSelector((state) => state.auth.token)
    const error = useSelector((state) => state.auth.error)
    const dispatch = useDispatch()

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(login({username, password})).then((res) => {
            setUsername('')
            setPassword('')
        })
    };

    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    return (
        <div className="w-full min-h-screen bg-gray-800 md:bg-gray-300">
            <div className="flex items-center justify-center">
                <div className="p-8 shadow-md w-96 bg-gray-800 rounded-[5px] mt-32 md:mt-16">
                    <h2 className="flex items-center justify-center text-4xl font-semibold mb-6 text-white">Login</h2>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="username" className="block text-white text-sm font-medium mb-2">Username</label>
                            <input type="text" id="username" name="username"
                                   className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                   placeholder="Enter your username" required
                                   value={username}
                                   onChange={handleUsername}/>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="password" className="block text-white text-sm font-medium mb-2">Password</label>
                            <input type="password" id="password" name="password"
                                   className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                   placeholder="Enter your password" required
                                   value={password}
                                   onChange={handlePassword}/>
                        </div>

                        <button type="submit"
                                className="mt-2 w-full bg-[#A427D0] text-white p-3 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue">
                            Login
                        </button>
                        {error ? <p className="text-red-600 justify-center items-center">{error}</p> : null}
                        {token ? <Navigate to='/report' replace={true} state={token}/> : null}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
