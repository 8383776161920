import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithdrawals } from '../redux/withdrawalSlice';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Card from '../components/Card';
import Table from '../components/Table';

const Transaction = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.withdrawal.users);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchWithdrawals({ token }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dispatch, token]);

    useEffect(() => {
        if (users) {
            console.log(users);
        }
    }, [users]);

    return (
        <div className="text-gray-900">
            <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <main className={`${isSidebarOpen ? 'md:w-[calc(100%-256px)]' : 'md:w-full'} ${isSidebarOpen ? 'md:ml-64' : 'md:ml-full'} bg-gray-50 min-h-screen transition-all main`}>
                <Navbar toggleSidebar={toggleSidebar} />
                <Table  title="Withdrawals List"
                        data={users}
                />
            </main>
        </div>
    );
};

export default Transaction;
