export const sidebar = [
    {
        id: 1,
        title: "Report",
        link: "/report",
        icon: "ri-folder-chart-line"
    },
    {
        id: 2,
        title: "Users",
        link: "/users",
        icon: "ri-shield-user-line"
    },
    {
        id: 3,
        title: "Withdrawal",
        link: "/withdrawal",
        icon: "ri-cash-line"
    },
    {
        id: 4,
        title: "Transaction",
        link: "/transaction",
        icon: "ri-file-transfer-line"
    },
];